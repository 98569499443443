import { useEffect, useState } from "react";

import countdownVec from "../assets/img/vectors/countdown-vector.svg";

const Countdown = ({ date }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(date ? date : "2024-01-01T00:00:00+06:00") - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60) / 24),
                hours: Math.floor(difference / (1000 * 60 * 60) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    return (
        <>
            {timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
                <div className="d-flex justify-content-center flex-wrap gap-1 gap-md-4">
                    <div className="countdown-item position-relative text-center">
                        <img src={countdownVec} alt="" className="img-fluid position-absolute top-0 start-0 w-100 h-100" />
                        <div className="counter">{timeLeft.days > 9 ? timeLeft.days : '0' + timeLeft.days}</div>
                        <div className="h6 text-uppercase fw-light mt-1">Giorni</div>
                    </div>
                    <div className="countdown-item position-relative text-center">
                        <img src={countdownVec} alt="" className="img-fluid position-absolute top-0 start-0 w-100 h-100" />
                        <div className="counter">{timeLeft.hours > 9 ? timeLeft.hours : '0' + timeLeft.hours}</div>
                        <div className="h6 text-uppercase fw-light mt-1">Ore</div>
                    </div>
                    <div className="countdown-item position-relative text-center">
                        <img src={countdownVec} alt="" className="img-fluid position-absolute top-0 start-0 w-100 h-100" />
                        <div className="counter">{timeLeft.minutes > 9 ? timeLeft.minutes : '0' + timeLeft.minutes}</div>
                        <div className="h6 text-uppercase fw-light mt-1">Minuti</div>
                    </div>
                    <div className="countdown-item position-relative text-center">
                        <img src={countdownVec} alt="" className="img-fluid position-absolute top-0 start-0 w-100 h-100" />
                        <div className="counter">{timeLeft.seconds > 9 ? timeLeft.seconds : '0' + timeLeft.seconds}</div>
                        <div className="h6 text-uppercase fw-light mt-1">Secondi</div>
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-center flex-wrap gap-4">
                    <div className="countdown-item position-relative text-center">
                        <img src={countdownVec} alt="" className="img-fluid position-absolute top-0 start-0 w-100 h-100" />
                        <div className="counter">00</div>
                        <div className="h6 text-uppercase fw-light mt-1">Giorni</div>
                    </div>
                    <div className="countdown-item position-relative text-center">
                        <img src={countdownVec} alt="" className="img-fluid position-absolute top-0 start-0 w-100 h-100" />
                        <div className="counter">00</div>
                        <div className="h6 text-uppercase fw-light mt-1">Ore</div>
                    </div>
                    <div className="countdown-item position-relative text-center">
                        <img src={countdownVec} alt="" className="img-fluid position-absolute top-0 start-0 w-100 h-100" />
                        <div className="counter">00</div>
                        <div className="h6 text-uppercase fw-light mt-1">Minuti</div>
                    </div>
                    <div className="countdown-item position-relative text-center">
                        <img src={countdownVec} alt="" className="img-fluid position-absolute top-0 start-0 w-100 h-100" />
                        <div className="counter">00</div>
                        <div className="h6 text-uppercase fw-light mt-1">Secondi</div>
                    </div>
                </div>
            )}
        </>

    )
}

export default Countdown