import React, { useEffect, useState } from 'react'
import { ApproveToken } from '../hooks/approveToken';
import { BuyToken } from '../hooks/buyToken';
// import { useAccount, useWaitForTransaction } from 'wagmi';
import { purchaseAbi, PurchaseContractAddress, UsdcTokenAddress } from '../hooks/abi';
import { GetAllowanceAmount } from '../hooks/getAllowanceAmount';
import { GetUserUsdcBalance } from '../hooks/getUserUsdcBalance';
import { GetTokenDecimals } from '../hooks/getTokenDecimals';
import { GetIsRegistered } from '../hooks/getIsRegistered';
import { GetUserRefer } from '../hooks/getUserRefer';
import { useAddress } from '@thirdweb-dev/react';

const Pricing = ({ title, badge, price, period, list, link }) => {

    // const { address, isConnecting, isConnected, isDisconnected } = useAccount();
    const address = useAddress();

    const addressZero = '0x0000000000000000000000000000000000000000';

    const [errorText, setErrorText] = useState('');
    const [referAddress, setReferAddress] = useState(addressZero);

    const allowance = GetAllowanceAmount(address, PurchaseContractAddress());
    const decimals = GetTokenDecimals(UsdcTokenAddress());
    const usdcBalance = GetUserUsdcBalance(address);
    const isReferRegistered = GetIsRegistered(referAddress);
    const isUserRegistered = GetIsRegistered(address);
    const currentRefer = GetUserRefer(address);

    const convertedAllowance = Number(allowance.data) / 10 ** Number(decimals.data);
    const convertedUsdcBalance = Number(usdcBalance.data) / 10 ** Number(decimals.data);
    let approveAmount = convertedAllowance < price ? price : 0;

    const approveTokenHook = ApproveToken(UsdcTokenAddress(), PurchaseContractAddress(), approveAmount);
    const buyTokenHook = BuyToken(UsdcTokenAddress(), price, referAddress);

    // const approveTokenWait = useWaitForTransaction({
    //     hash: approveTokenHook.data?.hash,
    // })
    // const buyTokenWait = useWaitForTransaction({
    //     hash: buyTokenHook.data?.hash,
    // })


    // const getRefer = async () => {
    //     if(currentRefer.data != addressZero){
    //         console.log("Current refer:", currentRefer.data);
    //         setReferAddress(currentRefer.data);
    //         return;
    //     }
    //     var lnkStr = window.location.href;   //document.URL;   
    //     let index = lnkStr.indexOf('?ref=');
    //     let referAdd = '';
    //     if (index >= 0) {
    //         referAdd = lnkStr.substring(index + 5, index + 5 + 42);
    //         setReferAddress(referAdd);
    //         console.log("referAdd:", referAdd)
    //     } else {
    //         setReferAddress(addressZero);
    //         console.log("referAddress:", addressZero);
    //     }
    // }

    useEffect(() => {
        const getRefer = async () => {
            if(currentRefer.data != addressZero){
                console.log("Current refer:", currentRefer.data);
                setReferAddress(currentRefer.data);
                return;
            }
            var lnkStr = window.location.href;   //document.URL;   
            let index = lnkStr.indexOf('?ref=');
            let referAdd = '';
            if (index >= 0) {
                referAdd = lnkStr.substring(index + 5, index + 5 + 42);
                setReferAddress(referAdd);
                console.log("referAdd:", referAdd)
            } else {
                setReferAddress(addressZero);
                console.log("referAddress:", addressZero);
            }
        }
        getRefer();
    }, [currentRefer.data]);

    const approveToken = () => {
        setErrorText("");
        if (convertedUsdcBalance < price) {
            setErrorText("You don't have enough stable coin balance to buy");
            return;
        }
        if (isUserRegistered.data == false) {
            setErrorText("You are not registered, First register");
            return;
        }
        if (referAddress != addressZero && isReferRegistered.data == false) {
            setErrorText("Your refer address is not registered");
            return;
        }
        if (referAddress == address) {
            setErrorText("You can't put your address as refer");
            return;
        }

        approveTokenHook.write?.({ gasLimit: 1500000 })
    }

    const buyToken = () => {
        setErrorText("");
        if (convertedUsdcBalance < price) {
            setErrorText("You don't have enough stable coin balance to buy");
            return;
        }
        if (referAddress == address) {
            setErrorText("You can't put your address as refer");
            return;
        }
        if (referAddress != addressZero && isReferRegistered.data == false) {
            setErrorText("Your refer address is not registered");
            return;
        }
        if (isUserRegistered.data == false) {
            setErrorText("You are not registered, First register");
            return;
        }
        if (convertedAllowance < price) {
            setErrorText("First approve the token");
            return;
        }
        buyTokenHook.write?.();
    }

    // useEffect(() => {
    //     console.log(convertedAllowance)
    // }, [decimals])

    useEffect(() => {
        if (approveTokenHook.isSuccess || buyTokenHook.isSuccess) {
            allowance.refetch();
        }
    }, [approveTokenHook, buyTokenHook]);

    return (
        <div className="pricing-card mb-4 h-100 d-flex flex-column">
            <div className="card-header">
                {title &&
                    <div className="title">
                        {title} {badge && <div className="badge">{badge}</div>}
                    </div>
                }
                {
                    price && <div className="main-price"> {price} USDC {period && "/" + period}</div>
                }
            </div>
            <div className="card-body">
                <ul>
                    {
                        list && list.map((el, i) => {
                            return (
                                <li key={i}>{el}</li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="card-footer mt-auto mb-0">
                <div className='d-flex gap-3'>
                    {/* {link && <a href={link} className="cbd-button btn-sm">BUY</a>} */}
                    {convertedAllowance < price && <button onClick={() => approveToken()} className="cbd-button btn-sm">{approveTokenHook.isLoading ? "Loading..." : "APPROVE"}</button>}
                    <button onClick={() => buyToken()} className="cbd-button btn-sm px-5">{buyTokenHook.isLoading ? "Loading..." : "Compra"}</button>
                    {/* <button onClick={() =>buyTokenHook.reset?.()} className="cbd-button btn-sm px-3">TBUY</button> */}
                </div>

                <p className='text-danger'>{errorText}</p>
                {approveTokenHook.isLoading && <p className='text-warning'>Approving stablecoin ...</p>}
                {buyTokenHook.isLoading && <p className='text-warning'>Buying token ...</p>}
                {buyTokenHook.isSuccess && <p className='text-success'>You bought token successfully !</p>}
                {buyTokenHook.isError && <p className='text-danger'>Buying failed !</p>}
            </div>
        </div>
    )
}

export default Pricing