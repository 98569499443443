const Tree = ({title, subtitle, datalist}) => {
  return (
    <div className="treeSteam">
        <div className="treeSteam-head position-relative d-flex flex-column align-items-center justify-content-center">
            {/* <div className="treeSteam-head-arrow position-absolute start-100">
                <LongArrow />
            </div> */}
            <div className="title">{title && title}</div>
            <div className="subtitle">{subtitle && subtitle}</div>
        </div>
        <div className="treeSteam-body">
            <div className="d-flex gap-1">
                {
                    datalist?.map((el, i) => {
                        return(
                            <div className="treeLeaves text-center" key={i}><p className="text-break">{el}</p></div>   
                        )
                    })
                }
                {/* <div className=""></div>   
                <div className="treeLeaves text-center"><p className="text-break">{datalist[0]}</p></div>   
                <div className=""></div>    */}
            </div>
        </div>
    </div>
  )
}

export default Tree