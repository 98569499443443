import React from 'react'

const Roadmap = ({ small }) => {
    return (
        <div className={`roadmap${small ? ' roadmap-sm' : ''}`}>
            <div className="roadmap-single">
                <div className="content-wrapper">
                    <h4 className='mb-3'>Q2</h4>
                    <ul className='ps-3 mb-2'>
                        <li>Distribuzione token CBD</li>
                        <li>Raccolta fondi</li>
                        <li>Creazione della community</li>
                    </ul>
                </div>
            </div>
            <div className="roadmap-single">
                <div className="content-wrapper">
                    <h4 className='mb-3'>Q3</h4>
                    <ul className='ps-3 mb-2'>
                        <li>Lancio dell'e-commerce relativo ai prodotti CBD</li>
                        <li>Sviluppo del mercato secondario P2P delle vendite di CBD</li>
                    </ul>
                </div>
            </div>
            <div className="roadmap-single">
                <div className="content-wrapper">
                    <h4 className='mb-3'>Q4</h4>
                    <ul className='ps-3 mb-2'>
                        <li>Sviluppo dell'architettura della banca decentralizzata</li>
                        <li>Lancio della banca decentralizzata</li>
                        <li>Ottimizzazione caratteristiche della banca, come le attività di prestito e di investimenti collateralizzati.</li>
                    </ul>
                </div>
            </div>
            <div className="roadmap-single">
                <div className="content-wrapper">
                    <h4 className='mb-3'>2024</h4>
                    <ul className='ps-3 mb-2'>
                        <li>Sviluppo dell'ecosistema CBD e della banca con struttura di app mobile.</li>
                        <li>Quotazione del token CBD in exchange centralizzati.</li>
                        <li>Creazione di prodotti finanziari legati a CBD e Canapa.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Roadmap