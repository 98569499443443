import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
// import {
//   EthereumClient,
//   modalConnectors,
//   walletConnectProvider,
// } from "@web3modal/ethereum";
// import { Web3Modal } from "@web3modal/react";
// import { configureChains, createClient, WagmiConfig } from "wagmi";
// import { arbitrum, mainnet, polygon, goerli } from "wagmi/chains";
import { ThirdwebProvider, coinbaseWallet, metamaskWallet, safeWallet, walletConnect } from '@thirdweb-dev/react';
import { Goerli, Polygon } from '@thirdweb-dev/chains';

// configure chains
// const chains = [polygon, goerli];
// Wagmi client
// const { provider } = configureChains(chains, [
//   walletConnectProvider({ projectId: process.env.REACT_APP_PROJECT_ID }),
// ]);
// const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: modalConnectors({ appName: "web3Modal", chains }),
//   provider,
// });
// Web3Modal Ethereum Client
// const ethereumClient = new EthereumClient(wagmiClient, chains);
console.log("walletconnect projectId:", process.env.REACT_APP_PROJECT_ID)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    {/* <WagmiConfig client={wagmiClient}> */}
    <ThirdwebProvider
      supportedWallets={[
        metamaskWallet(),
        coinbaseWallet(),
        walletConnect({
          projectId: process.env.REACT_APP_PROJECT_ID,
        }),
        safeWallet(),
      ]}
      activeChain={Polygon}
      supportedChains={[Polygon, Goerli]}
      clientId="dc0e86e561d6111976ea35c66b8e3aa8"
      >
      <App />
      </ThirdwebProvider>
    {/* </WagmiConfig> */}
    {/* <Web3Modal
          // projectId={process.env.NEXT_PUBLIC_PROJECT_ID}
          projectId={process.env.REACT_APP_PROJECT_ID}
          ethereumClient={ethereumClient}
          themeColor="green"
          themeMode="dark"
          themeBackground="gradient"
        /> */}
    </BrowserRouter>
  </React.StrictMode>
);