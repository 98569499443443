import React, { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import profileAvatar from "../assets/img/regular/avatar-lg.png";
import db from "../firebase";
import storage from "../storage"
import { ref, onValue, get, update, set, child } from "firebase/database";
import { ref as SRef , getDownloadURL, uploadBytesResumable } from "firebase/storage";

const Profile = () => {
  let userKey = localStorage.getItem("UserKey");

  const [avatar, setAvatar] = useState(
    "https://media.istockphoto.com/id/1407271745/photo/gray-background.jpg?b=1&s=170667a&w=0&k=20&c=aAk4qTe2O_TAt77Nx9ROXTXyGTgXN0FFn1eep4RkvC0="
  );
  const [fullName, setFullName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [residence, setResidence] = useState("");
  const [email, setEmail] = useState("");
  const [iban, setIban] = useState("");
  const [wallet, setWallet] = useState("");
  const [phone, setPhone] = useState("");

  async function getUserData(key) {
    let userRef = ref(db, "users/" + key);
    await get(userRef).then((snapshot) => {
      let userData = snapshot.val();
      setAvatar(userData.pdpLink);
      setFullName(userData.fullName);
      setResidence(userData.residence);
      setEmail(userData.email);
      setPhone(userData.phone);
      setCountryOfBirth(userData.countryOfBirth);
	  console.log(`"${countryOfBirth}"`)
      setDateOfBirth(userData.dateOfBirth);
      setIban(userData.IBAN);
      setWallet(userData.walletAddress);
    });
  }

  useEffect(() => {
	if (userKey && userKey != "" && userKey != " " && userKey != null) {
		getUserData(userKey);
	  } else {
		window.location.href = "/";
	  }
  }, [])
  

  async function updateAccountData(){
	let userRef = ref(db, "users/" + userKey);
	await update(userRef, {
		"fullName": fullName,
		"email": email,
		"phone": phone,
		"residence": residence,
		"IBAN": iban,
		"countryOfBirth": countryOfBirth,
		"dateOfBirth": dateOfBirth
	})
  }

  const fileReader = new FileReader();

  async function updateAvatar(f){
	const stroageRef = SRef(storage, `/users_pdp/${f.name}`);
    const uploadTask = uploadBytesResumable(stroageRef, f);
	setAvatar("https://miro.medium.com/v2/resize:fit:1400/0*ptDX0HfJCYpo9Pcs.gif");
	document.getElementById('updateDataBtn').disabled = true;
	uploadTask.on("state_changed", (snapshot) => {
		const progress =Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
		console.log(progress);
	},(error) => {
		alert(error);
	}, () => {
		getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
			setAvatar(downloadURL)
			await update(ref(db, 'users/'+userKey), {
				'pdpLink': downloadURL,
			});

		});
		document.getElementById('updateDataBtn').disabled = false;
	}
	
	)
  }

  return (
    <div className="global-element-bg global-padding">
      {/* UPDATE PHOTO */}
      <div className="update-photo global-element-cover d-flex gap-4 flex-wrap align-items-center justify-content-center justify-content-sm-between">
        <div className="d-flex flex-column flex-md-row align-items-center gap-2">
          <div className="avatar avatar-lg">
            <img src={avatar} alt="" className="img-fluid" />
          </div>
          <div className="fw-bold font-22px ms-1">{fullName}</div>
        </div>
        <button type="button" className="cbd-button" onClick={()=>{document.getElementById('newAvatar').click()}}>
          Update Avatar
        </button>
      </div>

      {/* PROFILE FORM TITLE */}
      <div className="h3 fw-light my-30">Account data</div>

      {/* PROFILE FORM */}
      <form className="row g-4" data-bs-theme="dark">
		<input hidden type="file" id="newAvatar" accept="image/png, image/jpeg" onChange={(e)=>updateAvatar(e.target.files[0])}/>
        <div className="col-md-6">
          <label htmlFor="formName" className="form-label">
            Full Name
          </label>
          <input
            type="text"
            className="form-control"
            id="formName"
            placeholder={fullName}
			onChange={(e) => {setFullName(e.target.value)}}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="dob" className="form-label">
            Date of Birth
          </label>
          <Form.Control type="date" name="dob" defaultValue={dateOfBirth}  onChange={(e) => {setDateOfBirth(e.target.value)}}/>
        </div>
        <div className="col-md-6">
          <div className="form-select-icon">
            <label htmlFor="formCountry" className="form-label">
              Country of Birth
            </label>
            <select id="formCountry" className="form-select" value={countryOfBirth} onChange={(e) => {setCountryOfBirth(e.target.value)}}>
				<option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">
                  Bosnia and Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">
                  British Indian Ocean Territory
                </option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">
                  Cocos (Keeling) Islands
                </option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">
                  Congo, The Democratic Republic of The
                </option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">
                  Falkland Islands (Malvinas)
                </option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">
                  French Southern Territories
                </option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">
                  Heard Island and Mcdonald Islands
                </option>
                <option value="Holy See (Vatican City State)">
                  Holy See (Vatican City State)
                </option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">
                  Iran, Islamic Republic of
                </option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">
                  Korea, Democratic People's Republic of
                </option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">
                  Lao People's Democratic Republic
                </option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">
                  Libyan Arab Jamahiriya
                </option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">
                  Macedonia, The Former Yugoslav Republic of
                </option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">
                  Micronesia, Federated States of
                </option>
                <option value="Moldova, Republic of">
                  Moldova, Republic of
                </option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">
                  Netherlands Antilles
                </option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">
                  Palestinian Territory, Occupied
                </option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">
                  Saint Kitts and Nevis
                </option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">
                  Saint Pierre and Miquelon
                </option>
                <option value="Saint Vincent and The Grenadines">
                  Saint Vincent and The Grenadines
                </option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">
                  Sao Tome and Principe
                </option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">
                  South Georgia and The South Sandwich Islands
                </option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">
                  Svalbard and Jan Mayen
                </option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">
                  Syrian Arab Republic
                </option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">
                  Tanzania, United Republic of
                </option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">
                  Turks and Caicos Islands
                </option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">
                  United States Minor Outlying Islands
                </option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">
                  Virgin Islands, British
                </option>
                <option value="Virgin Islands, U.S.">
                  Virgin Islands, U.S.
                </option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
				<option value="none">None</option>
              </select>
            
          </div>
        </div>
        <div className="col-md-6">
          <label htmlFor="formResidence" className="form-label">
            Residence
          </label>
          <input
            type="text"
            className="form-control"
            id="formResidence"
            placeholder={residence}
			onChange={(e) => {setResidence(e.target.value)}}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="formEmail" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="formEmail"
            placeholder={email}
			onChange={(e) => {setEmail(e.target.value)}}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="formIban" className="form-label">
            IBAN
          </label>
          <input
            type="text"
            className="form-control"
            id="formIban"
            placeholder={iban}
			onChange={(e) => {setIban(e.target.value)}}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="formWallet" className="form-label">
            Wallet
          </label>
          <input
            type="text"
            className="form-control"
            id="formWallet"
            placeholder={wallet}
			readOnly
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="formCountry" className="form-label">
            Phone number
          </label>
          <div className="input-group mb-3">
            <div className="form-select-icon">
              <select id="formCountry" className="form-select">
                <option data-countrycode="DZ" value="213">
                  +213
                </option>
                <option data-countrycode="AD" value="376">
                  +376
                </option>
                <option data-countrycode="AO" value="244">
                  +244
                </option>
                <option data-countrycode="AI" value="1264">
                  +1264
                </option>
                <option data-countrycode="AG" value="1268">
                  +1268
                </option>
                <option data-countrycode="AR" value="54">
                  +54
                </option>
                <option data-countrycode="AM" value="374">
                  +374
                </option>
                <option data-countrycode="AW" value="297">
                  +297
                </option>
                <option data-countrycode="AU" value="61">
                  +61
                </option>
                <option data-countrycode="AT" value="43">
                  +43
                </option>
                <option data-countrycode="AZ" value="994">
                  +994
                </option>
                <option data-countrycode="BS" value="1242">
                  +1242
                </option>
                <option data-countrycode="BH" value="973">
                  +973
                </option>
                <option data-countrycode="BD" value="880">
                  +880
                </option>
                <option data-countrycode="BB" value="1246">
                  +1246
                </option>
                <option data-countrycode="BY" value="375">
                  +375
                </option>
                <option data-countrycode="BE" value="32">
                  +32
                </option>
                <option data-countrycode="BZ" value="501">
                  +501
                </option>
                <option data-countrycode="BJ" value="229">
                  +229
                </option>
                <option data-countrycode="BM" value="1441">
                  +1441
                </option>
                <option data-countrycode="BT" value="975">
                  +975
                </option>
                <option data-countrycode="BO" value="591">
                  +591
                </option>
                <option data-countrycode="BA" value="387">
                  +387
                </option>
                <option data-countrycode="BW" value="267">
                  +267
                </option>
                <option data-countrycode="BR" value="55">
                  +55
                </option>
                <option data-countrycode="BN" value="673">
                  +673
                </option>
                <option data-countrycode="BG" value="359">
                  +359
                </option>
                <option data-countrycode="BF" value="226">
                  +226
                </option>
                <option data-countrycode="BI" value="257">
                  +257
                </option>
                <option data-countrycode="KH" value="855">
                  +855
                </option>
                <option data-countrycode="CM" value="237">
                  +237
                </option>
                <option data-countrycode="CA" value="1">
                  +1
                </option>
                <option data-countrycode="CV" value="238">
                  +238
                </option>
                <option data-countrycode="KY" value="1345">
                  +1345
                </option>
                <option data-countrycode="CF" value="236">
                  +236
                </option>
                <option data-countrycode="CL" value="56">
                  +56
                </option>
                <option data-countrycode="CN" value="86">
                  +86
                </option>
                <option data-countrycode="CO" value="57">
                  +57
                </option>
                <option data-countrycode="KM" value="269">
                  +269
                </option>
                <option data-countrycode="CG" value="242">
                  +242
                </option>
                <option data-countrycode="CK" value="682">
                  +682
                </option>
                <option data-countrycode="CR" value="506">
                  +506
                </option>
                <option data-countrycode="HR" value="385">
                  +385
                </option>
                <option data-countrycode="CU" value="53">
                  +53
                </option>
                <option data-countrycode="CY" value="90392">
                  +90392
                </option>
                <option data-countrycode="CY" value="357">
                  +357
                </option>
                <option data-countrycode="CZ" value="42">
                  +42
                </option>
                <option data-countrycode="DK" value="45">
                  +45
                </option>
                <option data-countrycode="DJ" value="253">
                  +253
                </option>
                <option data-countrycode="DM" value="1809">
                  +1809
                </option>
                <option data-countrycode="DO" value="1809">
                  +1809
                </option>
                <option data-countrycode="EC" value="593">
                  +593
                </option>
                <option data-countrycode="EG" value="20">
                  +20
                </option>
                <option data-countrycode="SV" value="503">
                  +503
                </option>
                <option data-countrycode="GQ" value="240">
                  +240
                </option>
                <option data-countrycode="ER" value="291">
                  +291
                </option>
                <option data-countrycode="EE" value="372">
                  +372
                </option>
                <option data-countrycode="ET" value="251">
                  +251
                </option>
                <option data-countrycode="FK" value="500">
                  +500
                </option>
                <option data-countrycode="FO" value="298">
                  +298
                </option>
                <option data-countrycode="FJ" value="679">
                  +679
                </option>
                <option data-countrycode="FI" value="358">
                  +358
                </option>
                <option data-countrycode="FR" value="33">
                  +33
                </option>
                <option data-countrycode="GF" value="594">
                  +594
                </option>
                <option data-countrycode="PF" value="689">
                  +689
                </option>
                <option data-countrycode="GA" value="241">
                  +241
                </option>
                <option data-countrycode="GM" value="220">
                  +220
                </option>
                <option data-countrycode="GE" value="7880">
                  +7880
                </option>
                <option data-countrycode="DE" value="49">
                  +49
                </option>
                <option data-countrycode="GH" value="233">
                  +233
                </option>
                <option data-countrycode="GI" value="350">
                  +350
                </option>
                <option data-countrycode="GR" value="30">
                  +30
                </option>
                <option data-countrycode="GL" value="299">
                  +299
                </option>
                <option data-countrycode="GD" value="1473">
                  +1473
                </option>
                <option data-countrycode="GP" value="590">
                  +590
                </option>
                <option data-countrycode="GU" value="671">
                  +671
                </option>
                <option data-countrycode="GT" value="502">
                  +502
                </option>
                <option data-countrycode="GN" value="224">
                  +224
                </option>
                <option data-countrycode="GW" value="245">
                  +245
                </option>
                <option data-countrycode="GY" value="592">
                  +592
                </option>
                <option data-countrycode="HT" value="509">
                  +509
                </option>
                <option data-countrycode="HN" value="504">
                  +504
                </option>
                <option data-countrycode="HK" value="852">
                  +852
                </option>
                <option data-countrycode="HU" value="36">
                  +36
                </option>
                <option data-countrycode="IS" value="354">
                  +354
                </option>
                <option data-countrycode="IN" value="91">
                  +91
                </option>
                <option data-countrycode="ID" value="62">
                  +62
                </option>
                <option data-countrycode="IR" value="98">
                  +98
                </option>
                <option data-countrycode="IQ" value="964">
                  +964
                </option>
                <option data-countrycode="IE" value="353">
                  +353
                </option>
                <option data-countrycode="IL" value="972">
                  +972
                </option>
                <option data-countrycode="IT" value="39">
                  +39
                </option>
                <option data-countrycode="JM" value="1876">
                  +1876
                </option>
                <option data-countrycode="JP" value="81">
                  +81
                </option>
                <option data-countrycode="JO" value="962">
                  +962
                </option>
                <option data-countrycode="KZ" value="7">
                  +7
                </option>
                <option data-countrycode="KE" value="254">
                  +254
                </option>
                <option data-countrycode="KI" value="686">
                  +686
                </option>
                <option data-countrycode="KP" value="850">
                  +850
                </option>
                <option data-countrycode="KR" value="82">
                  +82
                </option>
                <option data-countrycode="KW" value="965">
                  +965
                </option>
                <option data-countrycode="KG" value="996">
                  +996
                </option>
                <option data-countrycode="LA" value="856">
                  +856
                </option>
                <option data-countrycode="LV" value="371">
                  +371
                </option>
                <option data-countrycode="LB" value="961">
                  +961
                </option>
                <option data-countrycode="LS" value="266">
                  +266
                </option>
                <option data-countrycode="LR" value="231">
                  +231
                </option>
                <option data-countrycode="LY" value="218">
                  +218
                </option>
                <option data-countrycode="LI" value="417">
                  +417
                </option>
                <option data-countrycode="LT" value="370">
                  +370
                </option>
                <option data-countrycode="LU" value="352">
                  +352
                </option>
                <option data-countrycode="MO" value="853">
                  +853
                </option>
                <option data-countrycode="MK" value="389">
                  +389
                </option>
                <option data-countrycode="MG" value="261">
                  +261
                </option>
                <option data-countrycode="MW" value="265">
                  +265
                </option>
                <option data-countrycode="MY" value="60">
                  +60
                </option>
                <option data-countrycode="MV" value="960">
                  +960
                </option>
                <option data-countrycode="ML" value="223">
                  +223
                </option>
                <option data-countrycode="MT" value="356">
                  +356
                </option>
                <option data-countrycode="MH" value="692">
                  +692
                </option>
                <option data-countrycode="MQ" value="596">
                  +596
                </option>
                <option data-countrycode="MR" value="222">
                  +222
                </option>
                <option data-countrycode="YT" value="269">
                  +269
                </option>
                <option data-countrycode="MX" value="52">
                  +52
                </option>
                <option data-countrycode="FM" value="691">
                  +691
                </option>
                <option data-countrycode="MD" value="373">
                  +373
                </option>
                <option data-countrycode="MC" value="377">
                  +377
                </option>
                <option data-countrycode="MN" value="976">
                  +976
                </option>
                <option data-countrycode="MS" value="1664">
                  +1664
                </option>
                <option data-countrycode="MA" value="212">
                  +212
                </option>
                <option data-countrycode="MZ" value="258">
                  +258
                </option>
                <option data-countrycode="MN" value="95">
                  +95
                </option>
                <option data-countrycode="NA" value="264">
                  +264
                </option>
                <option data-countrycode="NR" value="674">
                  +674
                </option>
                <option data-countrycode="NP" value="977">
                  +977
                </option>
                <option data-countrycode="NL" value="31">
                  +31
                </option>
                <option data-countrycode="NC" value="687">
                  +687
                </option>
                <option data-countrycode="NZ" value="64">
                  +64
                </option>
                <option data-countrycode="NI" value="505">
                  +505
                </option>
                <option data-countrycode="NE" value="227">
                  +227
                </option>
                <option data-countrycode="NG" value="234">
                  +234
                </option>
                <option data-countrycode="NU" value="683">
                  +683
                </option>
                <option data-countrycode="NF" value="672">
                  +672
                </option>
                <option data-countrycode="NP" value="670">
                  +670
                </option>
                <option data-countrycode="NO" value="47">
                  +47
                </option>
                <option data-countrycode="OM" value="968">
                  +968
                </option>
                <option data-countrycode="PW" value="680">
                  +680
                </option>
                <option data-countrycode="PA" value="507">
                  +507
                </option>
                <option data-countrycode="PG" value="675">
                  +675
                </option>
                <option data-countrycode="PY" value="595">
                  +595
                </option>
                <option data-countrycode="PE" value="51">
                  +51
                </option>
                <option data-countrycode="PH" value="63">
                  +63
                </option>
                <option data-countrycode="PL" value="48">
                  +48
                </option>
                <option data-countrycode="PT" value="351">
                  +351
                </option>
                <option data-countrycode="PR" value="1787">
                  +1787
                </option>
                <option data-countrycode="QA" value="974">
                  +974
                </option>
                <option data-countrycode="RE" value="262">
                  +262
                </option>
                <option data-countrycode="RO" value="40">
                  +40
                </option>
                <option data-countrycode="RU" value="7">
                  +7
                </option>
                <option data-countrycode="RW" value="250">
                  +250
                </option>
                <option data-countrycode="SM" value="378">
                  +378
                </option>
                <option data-countrycode="ST" value="239">
                  +239
                </option>
                <option data-countrycode="SA" value="966">
                  +966
                </option>
                <option data-countrycode="SN" value="221">
                  +221
                </option>
                <option data-countrycode="CS" value="381">
                  +381
                </option>
                <option data-countrycode="SC" value="248">
                  +248
                </option>
                <option data-countrycode="SL" value="232">
                  +232
                </option>
                <option data-countrycode="SG" value="65">
                  +65
                </option>
                <option data-countrycode="SK" value="421">
                  +421
                </option>
                <option data-countrycode="SI" value="386">
                  +386
                </option>
                <option data-countrycode="SB" value="677">
                  +677
                </option>
                <option data-countrycode="SO" value="252">
                  +252
                </option>
                <option data-countrycode="ZA" value="27">
                  +27
                </option>
                <option data-countrycode="ES" value="34">
                  +34
                </option>
                <option data-countrycode="LK" value="94">
                  +94
                </option>
                <option data-countrycode="SH" value="290">
                  +290
                </option>
                <option data-countrycode="KN" value="1869">
                  +1869
                </option>
                <option data-countrycode="SC" value="1758">
                  +1758
                </option>
                <option data-countrycode="SD" value="249">
                  +249
                </option>
                <option data-countrycode="SR" value="597">
                  +597
                </option>
                <option data-countrycode="SZ" value="268">
                  +268
                </option>
                <option data-countrycode="SE" value="46">
                  +46
                </option>
                <option data-countrycode="CH" value="41">
                  +41
                </option>
                <option data-countrycode="SI" value="963">
                  +963
                </option>
                <option data-countrycode="TW" value="886">
                  +886
                </option>
                <option data-countrycode="TJ" value="7">
                  +7
                </option>
                <option data-countrycode="TH" value="66">
                  +66
                </option>
                <option data-countrycode="TG" value="228">
                  +228
                </option>
                <option data-countrycode="TO" value="676">
                  +676
                </option>
                <option data-countrycode="TT" value="1868">
                  +1868
                </option>
                <option data-countrycode="TN" value="216">
                  +216
                </option>
                <option data-countrycode="TR" value="90">
                  +90
                </option>
                <option data-countrycode="TM" value="7">
                  +7
                </option>
                <option data-countrycode="TM" value="993">
                  +993
                </option>
                <option data-countrycode="TC" value="1649">
                  +1649
                </option>
                <option data-countrycode="TV" value="688">
                  +688
                </option>
                <option data-countrycode="UG" value="256">
                  +256
                </option>
                <option data-countrycode="GB" value="44">
                  +44
                </option>
                <option data-countrycode="UA" value="380">
                  +380
                </option>
                <option data-countrycode="AE" value="971">
                  +971
                </option>
                <option data-countrycode="UY" value="598">
                  +598
                </option>
                <option data-countrycode="US" value="1">
                  +1
                </option>
                <option data-countrycode="UZ" value="7">
                  +7
                </option>
                <option data-countrycode="VU" value="678">
                  +678
                </option>
                <option data-countrycode="VA" value="379">
                  +379
                </option>
                <option data-countrycode="VE" value="58">
                  +58
                </option>
                <option data-countrycode="VN" value="84">
                  +84
                </option>
                <option data-countrycode="VG" value="84">
                  +1284
                </option>
                <option data-countrycode="VI" value="84">
                  +1340
                </option>
                <option data-countrycode="WF" value="681">
                  +681
                </option>
                <option data-countrycode="YE" value="969">
                  +969
                </option>
                <option data-countrycode="YE" value="967">
                  +967
                </option>
                <option data-countrycode="ZM" value="260">
                  +260
                </option>
                <option data-countrycode="ZW" value="263">
                  +263
                </option>
              </select>
            </div>
            <input
              type="tel"
              placeholder={phone}
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              className="form-control"
              required
			  onChange={(e) => {setPhone(e.target.value)}}
            />
          </div>
        </div>
		<div className="col-md-12">
		<button type="button" className="cbd-button" id="updateDataBtn" onClick={() => {updateAccountData()}} style={{marginLeft: "auto", marginRight: "auto"}}>
          Update account data
        </button>
		</div>
      </form>
    </div>
  );
};

export default Profile;
