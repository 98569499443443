import { BigNumber, ethers } from "ethers";
// import {
//   useContractRead,
//   useContractWrite,
//   usePrepareContractWrite,
// } from "wagmi";
import { tokenAbi, UsdcTokenAddress } from "./abi";
import { GetTokenDecimals } from "./getTokenDecimals";
import { str } from "./utils";
import { useContract, useContractWrite } from "@thirdweb-dev/react";

//get collateral balance of the user address in the exchange contract
export function ApproveToken(tokenAddress, spender, amount) {

  const tokenDecimals = GetTokenDecimals(tokenAddress);
  // const tokenDecimals = {data:18};
  const convertedAmount = amount
    ? str(amount * 10 ** Number(tokenDecimals.data))
    : 0;

    const { contract } = useContract(tokenAddress, tokenAbi);
    const writeObject = useContractWrite(contract, "approve");
    return {
      data: writeObject?.data,
      write: () => writeObject?.mutateAsync(
        {
          args: [spender, convertedAmount],
          overrides:{
            gasLimit:1500000
          }
        },
      ),
      isLoading: writeObject?.isLoading,
      isSuccess: writeObject?.isSuccess,
      isError: writeObject?.isError,
    }
  // const { config } = usePrepareContractWrite({
  //   address: tokenAddress,
  //   abi: tokenAbi,
  //   functionName: "approve",
  //   args: [spender, convertedAmount],
  //   enabled: true,
  //   overrides:{
  //     gasLimit: 1500000
  //   }
  // });
  
  // const { data, isLoading, isSuccess, write, status, reset } =
  //   useContractWrite(config);

  // if (!tokenAddress || !spender || !amount) {
  //   return {
  //     data: undefined,
  //     isLoading: undefined,
  //     isSuccess: undefined,
  //     write: undefined,
  //     status: undefined,
  //     reset:undefined
  //   };
  // } else {
  //   return {
  //     data,
  //     isLoading,
  //     isSuccess,
  //     write,
  //     status,
  //     reset
  //   };
  // }
}
