// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getDatabase, ref } from 'firebase/database';

import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIT30izewWDeS2jP-bhjKxkFEk4TyrEu8",
  authDomain: "cdbplatform.firebaseapp.com",
  databaseURL: "https://cdbplatform-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cdbplatform",
  storageBucket: "cdbplatform.appspot.com",
  messagingSenderId: "287163172748",
  appId: "1:287163172748:web:83c246340a5849f69d38a9",
  measurementId: "G-TN6YW220N4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



const storage = getStorage(app);

export default storage;