import { ethers } from 'ethers';
// import {useContractWrite, usePrepareContractWrite } from 'wagmi'
import { purchaseAbi, PurchaseContractAddress } from './abi';
import { GetTokenDecimals } from './getTokenDecimals';
import { str } from './utils';
import { useContract, useContractWrite } from '@thirdweb-dev/react';

//get collateral balance of the user address in the exchange contract
export function BuyToken(tokenAddress, amount, referAddress) {
  
   
    const tokenDecimals = GetTokenDecimals(tokenAddress);
    const convertedAmount = amount && tokenDecimals ? str(amount*10**Number(tokenDecimals.data)) : 0;
    // const convertedAmount = amount ? ethers.utils.parseEther(amount.toString()) : 0;

    const { contract } = useContract(PurchaseContractAddress(), purchaseAbi);
    const writeObject = useContractWrite(contract, "buyToken");
    return {
      data: writeObject?.data,
      write: () => writeObject?.mutateAsync(
        {
          args: [convertedAmount, referAddress],
          overrides:{
            gasLimit:1500000
          }
        },
      ),
      isLoading: writeObject?.isLoading,
      isSuccess: writeObject?.isSuccess,
      isError: writeObject?.isError,
    }

    // const { config } = usePrepareContractWrite({
    //   mode: 'recklesslyUnprepared',
    //     address: PurchaseContractAddress(),
    //     abi: purchaseAbi,
    //     functionName: 'buyToken',
    //     args: [convertedAmount, referAddress],
    //     overrides:{
    //       gasLimit: 1500000
    //     }
    //     });
    // // console.log("buy inputs", convertedAmount, referAddress)
    // const { data, isLoading, isSuccess, write, error, isError, reset } = useContractWrite(config);
    //   return {
    //     data,
    //     isLoading,
    //     isSuccess,
    //     write,
    //     error,
    //     reset,
    //     isError
    //   };
    // }
    
    
  
}