import React, { useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as Sidebar1 } from '../../assets/img/icons/sidebar-1.svg'
import { ReactComponent as Sidebar2 } from '../../assets/img/icons/sidebar-2.svg'
import { ReactComponent as Sidebar3 } from '../../assets/img/icons/sidebar-3.svg'
import { ReactComponent as Sidebar4 } from '../../assets/img/icons/sidebar-4.svg'
import { ReactComponent as Sidebar5 } from '../../assets/img/icons/sidebar-5.svg'
import { ReactComponent as Sidebar6 } from '../../assets/img/icons/sidebar-6.svg'
import { ReactComponent as Sidebar7 } from '../../assets/img/icons/sidebar-7.svg'
import { ReactComponent as Sidebar8 } from '../../assets/img/icons/sidebar-8.svg'
import { ReactComponent as Sidebar9 } from '../../assets/img/icons/sidebar-9.svg'
import { ReactComponent as Sidebar10 } from '../../assets/img/icons/sidebar-10.svg'
import { ReactComponent as Sidebar11 } from '../../assets/img/icons/sidebar-11.svg'
import { ReactComponent as Sidebar12 } from '../../assets/img/icons/sidebar-12.svg'
import { ReactComponent as Sidebar13 } from '../../assets/img/icons/sidebar-13.svg'
import { ReactComponent as Sidebar14 } from '../../assets/img/icons/sidebar-14.svg'
import { ReactComponent as Sidebar15 } from '../../assets/img/icons/sidebar-15.svg'
import { NavLink } from 'react-router-dom';

const Sidebar = ({ sideToggle, setSideToggle }) => {

  

  return (
    <OutsideClickHandler
      onOutsideClick={() => { setSideToggle(false) }}
    >
      <div className={`cbd-sidebar${sideToggle ? ' show' : ''}`}>
        <div className="label">MAIN MENU</div>
        <ul className="sidebar-list">
          <li>
            <NavLink to={"/" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar1 /></div>
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/membership" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar12 /></div>
              <span>Pacchetti</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/profile` + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar2 /></div>
              <span>Profilo</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/my-token" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar3 /></div>
              <span>Miei Token</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/rewards" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar4 /></div>
              <span>B-Rewards</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/community" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar5 /></div>
              <span>Mia Rete</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/e-commerce" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar6 /></div>
              <span>E-commerce</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/exchange" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar7 /></div>
              <span>Exchange</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/defi-bank" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar8 /></div>
              <span>De.Fi Bank</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/documents" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar9 /></div>
              <span>Documenti</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/contact" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar10 /></div>
              <span>Contatti</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/roadmap" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar11 /></div>
              <span>Roadmap</span>
            </NavLink>
          </li>

          <li>
            <NavLink to={"/e-learning" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar13 /></div>
              <span>Casi di Studio</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/investors" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar14 /></div>
              <span>Investitori</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/partners" + window.location.search} className={({ isActive }) => isActive ? 'active' : undefined}>
              <div className="icon"><Sidebar15 /></div>
              <span>Finanziatori</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </OutsideClickHandler>
  )
}

export default Sidebar