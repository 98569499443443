import { ethers } from 'ethers';
// import { useContractRead, useContractWrite, usePrepareContractWrite } from 'wagmi'
import { purchaseAbi, PurchaseContractAddress, tokenAbi, UsdcTokenAddress } from './abi';
import { useContract, useContractRead } from '@thirdweb-dev/react';


//get collateral balance of the user address in the exchange contract
export function GetUserRefer(userAddress) {

  const { contract } = useContract(PurchaseContractAddress(), purchaseAbi);
  const refer = useContractRead(
    contract,
    "refer",
    [userAddress]
  );
  return refer;

  // const refer = useContractRead({
  //   address: PurchaseContractAddress(),
  //   abi: purchaseAbi,
  //   functionName: 'refer',
  //   args: [userAddress],
  // });
  // return refer;
}