import { useState, lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navbar, Sidebar } from './components'

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/index.scss'

import Profile from './pages/Profile';

import { useAddress, useChainId, useNetworkMismatch, useSDK, useSigner, useSwitchChain } from "@thirdweb-dev/react";
import { Binance, Polygon } from "@thirdweb-dev/chains";

const Dashboard = lazy(() => wait(500).then(() => import('./pages/Dashboard')));
const MyToken = lazy(() => wait(500).then(() => import('./pages/MyToken')));
const Reward = lazy(() => wait(500).then(() => import('./pages/Reward')));
const Community = lazy(() => wait(500).then(() => import('./pages/Community')));
const Ecommerce = lazy(() => wait(500).then(() => import('./pages/Ecommerce')));
const Exchange = lazy(() => wait(500).then(() => import('./pages/Exchange')));
const Bank = lazy(() => wait(500).then(() => import('./pages/Bank')));
const Documents = lazy(() => wait(500).then(() => import('./pages/Documents')));
const Contact = lazy(() => wait(500).then(() => import('./pages/Contact')));
const Roadmap = lazy(() => wait(500).then(() => import('./pages/Roadmap')));
const Membership = lazy(() => wait(500).then(() => import('./pages/Membership')));
const Elearning = lazy(() => wait(500).then(() => import('./pages/Elearning')));
const Investors = lazy(() => wait(500).then(() => import('./pages/Investors')));
const Partners = lazy(() => wait(500).then(() => import('./pages/Partners')));
const Register = lazy(() => wait(500).then(() => import('./pages/Register')));

const wait = (time) => {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}


function App() {

  const [sideToggle, setSideToggle] = useState(false);
  const currentChainId = useChainId();

  const switchChain = useSwitchChain();


  useEffect(() => {
    
    if(currentChainId){
    if(currentChainId !== 512 && currentChainId !== 5){
      console.log("HEELLO")
      switchChain(512);
    }
    }
  },[currentChainId])

  const DashNavbar = (item) => {

    return (
      <>
        <Navbar sideToggle={sideToggle} setSideToggle={setSideToggle} connect />
        <Sidebar sideToggle={sideToggle} setSideToggle={setSideToggle} />
        <Suspense fallback={<></>}>
          {item}
        </Suspense>
      </>
    );
  };

  return (
    <>
      {/* App Area */}
      <div className="app-area">
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/" element={DashNavbar(<Dashboard />)} />
          <Route path="/profile" element={DashNavbar(<Profile />)} />
          <Route path="/my-token" element={DashNavbar(<MyToken />)} />
          <Route path="/rewards" element={DashNavbar(<Reward />)} />
          <Route path="/community" element={DashNavbar(<Community />)} />
          <Route path="/e-commerce" element={DashNavbar(<Ecommerce />)} />
          <Route path="/exchange" element={DashNavbar(<Exchange />)} />
          <Route path="/defi-bank" element={DashNavbar(<Bank />)} />
          <Route path="/documents" element={DashNavbar(<Documents />)} />
          <Route path="/contact" element={DashNavbar(<Contact />)} />
          <Route path="/roadmap" element={DashNavbar(<Roadmap />)} />
          <Route path="/membership" element={DashNavbar(<Membership />)} />
          <Route path="/e-learning" element={DashNavbar(<Elearning />)} />
          <Route path="/investors" element={DashNavbar(<Investors />)} />
          <Route path="/partners" element={DashNavbar(<Partners />)} />
          <Route path="*" element={DashNavbar(<Dashboard />)} />
        </Routes>
      </div>
    </>
  );
}

export default App;
