import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import profileAvatar from '../../assets/img/regular/profile-avatar.png';
import logo from '../../assets/img/regular/logo.png';

import { ReactComponent as Connect } from '../../assets/img/icons/connect.svg';
import { ReactComponent as AngleDown } from '../../assets/img/icons/angle-down.svg';
import { ReactComponent as Menu } from '../../assets/img/icons/menu.svg';
import { ReactComponent as Sidebar1 } from '../../assets/img/icons/sidebar-1.svg'
import { ReactComponent as Sidebar2 } from '../../assets/img/icons/sidebar-2.svg'
import { ReactComponent as Sidebar3 } from '../../assets/img/icons/sidebar-3.svg'
import { ReactComponent as Sidebar4 } from '../../assets/img/icons/sidebar-4.svg'
// import { Web3Button } from '@web3modal/react';
// import { useAccount } from 'wagmi'

import db from '../../firebase'
import { ref, onValue, get, update, set, child } from "firebase/database";
import { ConnectWallet, useAddress } from '@thirdweb-dev/react';
import { polygonPurchaseContractAddress } from '../../hooks/abi';




const Navbar = ({ sideToggle, setSideToggle, profile, connect }) => {

	// const { address, isConnected } = useAccount();
    const address = useAddress();


	const [show, setShow] = useState(false);
	const dropdownToggle = (e) => {
		e.stopPropagation();
		setShow(!show);
	}
	const sidebarToggle = (e) => {
		setSideToggle(!sideToggle);
	}

	function passwordlessLogin(snapshot) {
		let key = snapshot.key;
		localStorage.setItem('UserKey', snapshot.key);
		console.log(key)
	}

	async function checkUserExists(adr) {
		const userRef = ref(db, 'users/' + adr)
		await get(userRef).then(async (snapshot) => {
			let dt = snapshot.val();
			if (dt == null) {
				await set(userRef, {
					"fullName": "New User",
					"email": "no email yet",
					"phone": "+xxx xxxxxxxxx",
					"dateOfBirth": "dd/mm/yyyy",
					"countryOfBirth": "none",
					"pdpLink": "https://media.istockphoto.com/id/1407271745/photo/gray-background.jpg?b=1&s=170667a&w=0&k=20&c=aAk4qTe2O_TAt77Nx9ROXTXyGTgXN0FFn1eep4RkvC0=",
					"residence": "none",
					"walletAddress": address.toString(),
					"IBAN": "none"
				})

			} else {
				passwordlessLogin(snapshot)
			}
		})
	}

	useEffect(() => {
		if (address) {
			checkUserExists(address)
		} else {
			localStorage.removeItem('UserKey')
		}
	})


	return (
		<div className="cbd-navbar d-flex align-items-center">
			<div className="container-fluid w-100">
				<div className="row align-items-center justify-content-between g-0">
					<div className="col-auto">
						<Link to="/">
							<img src={logo} alt="" className="img-fluid nav-logo" />
						</Link>
					</div>
					<div className="col-auto">
						<div className="mobile-icons d-flex gap-2 gap-sm-3 align-items-center justify-content-end">
							<a href={`https://polygonscan.com/address/${polygonPurchaseContractAddress}`} className="cursor-pointer icon-square icon-circle icon-connect">
								<Connect />
							</a>
							{connect &&
								// <>
								// 	<a href="/#" className="icon-square icon-circle icon-gradient d-sm-none">
								// 		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className='d-sm-none'>
								// 			<path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
								// 		</svg>
								// 	</a>
								// 	<a href="/#" className="btn-sm cbd-button gap-1 d-none d-sm-inline">Connect Wallet</a>
								// </>
								// <Web3Button />
								<ConnectWallet
									dropdownPosition={{
										side: "bottom",
										align: "center",
									}}
								/>

							}
							{profile &&
								<OutsideClickHandler
									onOutsideClick={() => { setShow(false) }}
								>
									<div className="cursor-pointer position-relative d-flex align-items-center gap-1 gap-lg-3" onClick={dropdownToggle}>
										<div className="avatar">
											<img src={profileAvatar} alt="" className="img-fluid" />
										</div>
										<div className="me-2 d-none d-lg-block">
											<div className="font-sm fw-bold">Jennifer Thin</div>
											<div className="fw-light">jenniferthin@gmail.com</div>
										</div>
										<div className="d-none d-lg-block">
											<AngleDown />
										</div>
										<ul className={`position-absolute top-100 end-0 mt-3 dropdown-menu dropdown-menu-dark show${show ? '' : ' d-none'}`}>
											<li>
												<NavLink to={"/" + window.location.search} className="dropdown-item d-flex gap-2">
													<div className="icon"><Sidebar1 /></div>
													<span>My Dashboard</span>
												</NavLink>
											</li>
											<li>
												<NavLink to={"/profile" + window.location.search} className="dropdown-item d-flex gap-2">
													<div className="icon"><Sidebar2 /></div>
													<span>My Profile</span>
												</NavLink>
											</li>
											<li>
												<NavLink to={"/my-token" + window.location.search} className="dropdown-item d-flex gap-2">
													<div className="icon"><Sidebar3 /></div>
													<span>My Token</span>
												</NavLink>
											</li>
											<li><hr className="dropdown-divider" /></li>
											<li>
												<NavLink to={"/rewards" + window.location.search} className="dropdown-item d-flex gap-2">
													<div className="icon"><Sidebar4 /></div>
													<span>B-Rewards</span>
												</NavLink>
											</li>
										</ul>
									</div>
								</OutsideClickHandler>
							}
							<div className="position-relative">
								<div className={`cursor-pointer position-absolute top-0 start-0 h-100 w-100 ${sideToggle ? '' : 'd-none'}`}></div>
								<div className="cursor-pointer icon-square d-flex d-lg-none" onClick={sidebarToggle}>
									<Menu />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Navbar