import { ethers } from 'ethers';
// import { useContractRead, useContractWrite, usePrepareContractWrite } from 'wagmi'
import { tokenAbi, UsdcTokenAddress } from './abi';
import { useContract, useContractRead } from '@thirdweb-dev/react';


//get collateral balance of the user address in the exchange contract
export function GetUserUsdcBalance(ownerAddress) {

  const { contract } = useContract(UsdcTokenAddress(), tokenAbi);
  const allowanceAmount = useContractRead(
    contract,
    "balanceOf",
    [ownerAddress]
  );
  return allowanceAmount;

  // const allowanceAmount = useContractRead({
  //   address: UsdcTokenAddress(),
  //   abi: tokenAbi,
  //   functionName: 'balanceOf',
  //   args: [ownerAddress],
  // });
  // return allowanceAmount;
}